<template>
  <div class="main">
    <div class="apply-teacher">
        <div class="text-center">
          <h3 class="title">{{ $t("top_page.lecturer_form") }}</h3>
        </div>
        <div class="card">
          <div class="card-body">
            <Alert :visible="message !== null" :message="message" :type="alertType" />
            <FormView @submit="onSubmit">
              <div class="group-frm">
                <InlineInput label="表示名(ニックネーム)" v-model="user_info.nickname" required isDisabled/>
              </div>
              <div class="group-frm">
                <InlineInput label="メールアドレス" v-model="user_info.email" isDisabled />
              </div>
              <div class="group-frm">
                <InlineInput label="電話番号" v-model="user_info.phone" isDisabled />
              </div>
              <div class="group-frm">
                <InlineInput label="氏名" v-model="user_info.family_name" required />
              </div>
              <div class="group-frm">
                <InlineInput label="郵便番号" v-model="user_info.postal_code" required/>
              </div>
               <div class="group-frm">
                <InlineInput label="都道府県" v-model="user_info.prefectures" required/>
              </div>
               <div class="group-frm">
                <InlineInput label="以降の住所" v-model="user_info.address_after" required/>
              </div>
              <div class="group-frm">
                <InlineInput label="X" v-model="user_info.twitter_url" />
              </div>
              <div class="group-frm">
                <InlineInput label="Instagram" v-model="user_info.instagram" />
              </div>
              <div class="group-frm">
                <InlineInput label="Facebook" v-model="user_info.facebook" />
              </div>
              <div class="group-frm">
                <InlineInput label="その他（ブログ等）" v-model="user_info.other" />
              </div>
              <div class="group-frm">
                <TextArea
                  label="自己紹介"
                  v-model="user_info.apply_teacher_introduction"
                  required
                />
              </div>
              <div class="group-frm">
                <TextArea
                  label="得意ジャンル"
                  v-model="user_info.favorite_genre"
                  required
                />
              </div>
              <div class="group-frm">
                <div class="text-center">
                  <Button
                    type="submit"
                    class="btn btn-primary"
                    :name="isSubmitting ? '送信...' : '送信'"
                    :disabled="isSubmitting"
                    @handleClick()="onSubmit"
                  />
                </div>
              </div>
            </FormView>
          </div>
        </div>
    </div>
    <MetaTags/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      user_info: {
        nickname: null,
        family_name: null,
        email: null,
        phone: null,
        postal_code: null,
        prefectures: null,
        address_after: null,
        apply_teacher_introduction: null,
        favorite_genre: null
      },
      alertType: "alert-success",
      isSubmitting: false,
      message: null,
    };
  },
  async created() {
    if (this.user.info && this.user.info.member_id) {
      [
        this.user_info.member_id,
        this.user_info.family_name,
        this.user_info.email,
        this.user_info.phone,
        this.user_info.nickname,
        this.user_info.apply_teacher_introduction,
      ] = [
        this.user.info.member_id,
        this.user.info.family_name,
        this.user.info.email,
        this.user.info.phone,
        this.user.info.nickname,
        this.user.apply_teacher.introduction,
      ];
    } else {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapGetters({
      user: "member/getUser",
      member: "getMember",
    }),
  },
  methods: {
    ...mapActions({
      applyToTeacher: "applyTeacher/applyToTeacher",
      getMMSMember: "getMMSMember",
    }),
    async onSubmit() {
      const verified = await this.checkVerifyEMail();
      if(!verified) {
        return;
      }
      this.isSubmitting = true;
      let response = await this.applyToTeacher(this.user_info);
      let { data } = response;
      if(data && data.account_link){
        //this.$router.push("/");
        window.location.href = data.account_link.url;
      }
      if (response.data?.status == "pending") {
        this.message = this.$t("top_page.message.pending");
        this.alertType = "alert-primary";
        setTimeout(() => {
          this.$router.push("/");
        }, 4000);
      } else if (response.data?.status == "sucess") {
        this.message = this.$t("top_page.message.sucess");
        setTimeout(() => {
          this.$router.push("/");
        }, 4000);
      } else {
        this.message = this.$t("top_page.message.error");
        this.alertType = "alert-danger";
      }
      this.isSubmitting = false;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async checkVerifyEMail() {

      await this.getMMSMember();
      if (this.member.verified) {
        return true;
      }
      window.$("#btn-verify-email").click();
      window.MMSCallback.setVerifiedEmailListener(async (status) => {
        if (status) {
          this.onSubmit();
        }
      });
      return false;
    }
  },
};
</script>